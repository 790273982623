

































































































































































































































































import Vue from "vue"

import BaseLink from "@/components/BaseLink.vue"
import {
  newEmailLink,
  newTelephoneLink
} from "@/model/Link"

export default Vue.extend({
  components: {
    BaseLink
  },

  data() {
    return {
      link: {
        email: newEmailLink(),
        telephone: newTelephoneLink()
      }
    }
  }
})
